import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FolderType } from 'frontend/api/generated';
import { Folder, FolderPerson, FolderPlus, KindlyBlob, Trash } from 'frontend/assets/icons';
import type { MenuOverlayOptions } from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES } from 'frontend/constants';
import CreateFolder, { type CreateFolderArgs } from 'frontend/features/Library/modals/CreateFolder/CreateFolder';
import DeleteFolder, { type DeleteFolderArgs } from 'frontend/features/Library/modals/DeleteFolder/DeleteFolder';
import TransferFolder, {
  type TransferFolderArgs,
} from 'frontend/features/Library/modals/TransferFolder/TransferFolder';
import UpdateFolder, { type UpdateFolderArgs } from 'frontend/features/Library/modals/UpdateFolder/UpdateFolder';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

export function useFolderContextMenu({
  folder,
  botOrSkillParams,
}: {
  folder: Partial<FolderType>;
  botOrSkillParams: BotOrSkill;
}): {
  actions: MenuOverlayOptions;
  contextMenuId: string;
} {
  const navigate = useNavigate();
  const { buildType, buildId } = useBotOrSkill();
  const [showTransferFolderModal] = useModal(TransferFolder);
  const [showCreateFolder] = useModal(CreateFolder);
  const [showUpdateFolder] = useModal(UpdateFolder);
  const [showDeleteFolder] = useModal(DeleteFolder);

  const actions = useMemo(() => {
    const target = `new?topicId=${folder.id}`;
    const newDialogueUrl = getBuildUrl({ buildType, buildId, dialogueType: DIALOGUE_TYPES.REGULAR, target });

    const actionsArray: MenuOverlayOptions = [
      {
        text: 'New dialogue',
        icon: KindlyBlob,
        onClick: () => navigate(newDialogueUrl),
      },
      {
        text: 'New folder',
        icon: FolderPlus,
        onClick: () =>
          showCreateFolder({
            parentTopicId: folder.id,
            buildIdObject: botOrSkillParams.buildIdObject,
          } as CreateFolderArgs),
      },
      'separator',
      {
        text: 'Edit folder',
        icon: Folder,
        onClick: () =>
          showUpdateFolder({
            folder,
            buildIdObject: botOrSkillParams.buildIdObject,
          } as UpdateFolderArgs),
      },
      {
        text: 'Transfer folder',
        icon: FolderPerson,
        onClick: () => showTransferFolderModal({ folder, botOrSkillParams } as TransferFolderArgs),
      },
      'separator',
      {
        text: 'Delete folder',
        icon: Trash,
        onClick: () => showDeleteFolder({ folder, botOrSkillParams } as DeleteFolderArgs),
        color: 'red',
      },
    ];

    return actionsArray;
  }, [
    folder,
    botOrSkillParams,
    buildType,
    buildId,
    navigate,
    showCreateFolder,
    showDeleteFolder,
    showUpdateFolder,
    showTransferFolderModal,
  ]);

  return { actions, contextMenuId: `library-context-menu` };
}
