import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { type FolderType, type MutationTransferFolderArgs, TransferFolderDocument } from 'frontend/api/generated';
import { REFETCH_LIBRARY } from 'frontend/features/Library/utils/refetch';
import { useToast } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';

import TransferForm, { NONE_SELECTED } from '../TransferForm';

export type TransferFolderArgs = {
  folder: FolderType;
  botOrSkillParams: BotOrSkill;
};

export default function TransferFolder({
  hide,
  args,
}: {
  hide: (...args: unknown[]) => void;
  args: TransferFolderArgs;
}) {
  const toast = useToast();
  const { folder, botOrSkillParams } = args;
  const [transfer] = useMutation(TransferFolderDocument, REFETCH_LIBRARY);

  const onSubmit = useCallback(
    async (values: Pick<NonNullable<MutationTransferFolderArgs>, 'deleteOriginal' | 'botId' | 'skillId'>) => {
      const variables: NonNullable<MutationTransferFolderArgs> = {
        folderId: folder.id,
        botId: values.botId === NONE_SELECTED ? null : values.botId,
        skillId: values.skillId === NONE_SELECTED ? null : values.skillId,
        deleteOriginal: values.deleteOriginal,
        transferLabels: true, // TODO: make some UI for the user to pick this
      };
      await transfer({
        variables,
      });

      toast.success('The folder is being transferred. This may take a moment...');
    },
    [toast, folder, transfer],
  );

  const initialValues = useMemo(
    () => ({
      name: folder.name,
      deleteOriginal: false,
      botId: NONE_SELECTED,
      skillId: NONE_SELECTED,
    }),
    [folder.name],
  );

  return (
    <TransferForm
      hide={hide}
      onSubmit={onSubmit}
      initialValues={initialValues}
      type="FOLDER"
      botOrSkillParams={botOrSkillParams}
    />
  );
}
