import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { LoaderSwitch } from 'frontend/components';

import styles from './Insights.scss';
import Conversations from './components/Conversations/Conversations';
import Overview from './components/Overview/Overview';
import PanelMenu from './components/PanelMenu/PanelMenu';
import useLayout from './hooks/useLayout';
import { useBotSettings } from '../BotSettings/hooks';

const Insights = () => {
  useLayout();
  const { botId } = useParams();

  const { botSettings, loading } = useBotSettings(botId);
  return (
    <div className={styles.insightsWrapper}>
      <PanelMenu />
      <LoaderSwitch loading={!!loading}>
        <Routes>
          <Route path="/chat" element={<Conversations />} />
          <Route path="/chat/:chatId" element={<Conversations />} />
          {botSettings?.insightsOverviewEnabled && <Route path="/overview" element={<Overview />} />}
          <Route path="*" element={<Navigate to="chat" />} />
        </Routes>
      </LoaderSwitch>
    </div>
  );
};

export default Insights;
