import { useMutation } from '@apollo/client';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';

import { getOptimisticResponse } from 'frontend/api/cacheHelpers';
import { type FolderType, UpdateFolderDocument, type UpdateFolderMutationVariables } from 'frontend/api/generated';
import FolderForm from 'frontend/features/Library/modals/FolderForm/FolderForm';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export type UpdateFolderArgs = {
  folder: FolderType;
  buildIdObject: BuildIdObject;
};

function UpdateFolder({ hide, args: { folder, buildIdObject } }: { hide: () => void; args: UpdateFolderArgs }) {
  const [update] = useMutation(UpdateFolderDocument);
  const toast = useToast();
  const initialValues = useMemo(() => pick(folder, ['name', 'isActive']), [folder]);

  const onSubmit = useCallback(
    async (folderInput: UpdateFolderMutationVariables['folderInput']) => {
      const mutation = 'updateFolder';
      const response = { ...buildIdObject, ...folder, ...folderInput };
      const optimisticResponse = getOptimisticResponse({ response, mutation, typeName: 'FolderType' });

      const result = await update({
        variables: { ...buildIdObject, folderId: folder.id, folderInput },
        ...optimisticResponse,
      });
      const folderResultName = result.data!.updateFolder!.name;

      toast.success(`Folder '${folderResultName}' was updated!'`);
    },
    [buildIdObject, toast, folder, update],
  );

  return <FolderForm onSubmit={onSubmit} initialValues={initialValues} actionName="Edit" hide={hide} />;
}

export default UpdateFolder;
