import cx from 'classnames';
import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import type { FolderType } from 'frontend/api/generated';
import { Loader } from 'frontend/components';
import ContextMenu from 'frontend/components/ContextMenu/ContextMenu';
import MenuOverlay from 'frontend/components/subcomponents/MenuOverlay/MenuOverlay';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { useDropInFolder } from 'frontend/features/Library/components/FolderItem/useDropInFolder';
import { useFolderContextMenu } from 'frontend/features/Library/components/FolderItem/useFolderContextMenu';
import LibraryFolder from 'frontend/features/Library/components/LibraryFolder/LibraryFolder';
import { topicsInBreadcrumbsChecker } from 'frontend/features/Library/utils/breadcrumbs';
import { useBotOrSkill, useCombineRefs, useRemoveDefaultDragPreview, useToggle } from 'frontend/hooks';

import { type DropFolderItem, canDrop } from './utils';
import libraryStyles from '../../Library.scss';
import { useBreadcrumbs, useIndentationStyle } from '../../hooks';
import { getFolderIconClassnames } from '../../utils';
import LibraryItemContents from '../LibraryItemContents';

type Props = {
  folder: Pick<FolderType, 'id' | 'name' | 'isActive' | 'parentFolderId'>;
  skillId?: string;
  selectedLanguage: string;
  parentDisabled: boolean;
  indentationLevel: number;
};

function FolderItem({ folder, selectedLanguage, skillId, parentDisabled, indentationLevel }: Props) {
  const botOrSkillParams = useBotOrSkill();
  const { buildIdObject } = botOrSkillParams;
  const { id, name, isActive, parentFolderId } = folder;
  const disabled = !isActive || parentDisabled;
  const isSubscription = Boolean(skillId && buildIdObject && 'botId' in buildIdObject);

  const [showLoader, setShowLoader] = useState(false);
  const [open, toggleOpen] = useToggle();

  const { isInBreadcrumbs } = useBreadcrumbs({
    autoOpen: false,
    open,
    toggleOpen,
    checkBreadcrumbs: topicsInBreadcrumbsChecker(id!),
  });

  const item: DropFolderItem = {
    type: 'FOLDER',
    id,
    name,
    parentFolderId,
  };
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    item,
    type: 'FOLDER',
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    canDrag: () => !isSubscription,
  });

  const drop = useDropInFolder({
    setShowLoader,
    buildIdObject: buildIdObject!,
    targetFolderId: id,
    name: name!,
    currentLanguage: selectedLanguage,
  });

  const [{ isOver, canDrop: canDropOnCurrent }, dropRef] = useDrop({
    accept: ['DIALOGUE', 'FOLDER'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: canDrop({ isSubscription, id: id! }) as () => boolean,
    drop,
  });

  const { actions, contextMenuId } = useFolderContextMenu({ folder, botOrSkillParams });
  const indentationStyle = useIndentationStyle(indentationLevel);

  const dragAndDropRef = useCombineRefs(dragRef, dropRef);
  useRemoveDefaultDragPreview(previewRef);

  const active = isInBreadcrumbs && !open;
  const titleClassName = cx(libraryStyles.title, getFolderIconClassnames(open));

  return (
    <>
      <ContextMenu
        id={contextMenuId}
        disabled={isSubscription || actions.length === 0}
        overlay={<MenuOverlay options={actions} />}
      >
        <LibraryFolder
          ref={dragAndDropRef}
          toggleOpen={toggleOpen}
          disabled={disabled}
          isDragging={isDragging}
          canDrop={canDropOnCurrent}
          isOver={isOver}
          active={active}
          title={name}
        >
          <span style={indentationStyle} className={titleClassName}>
            {name}
            {showLoader && <Loader className={libraryStyles.libraryLoader} />}
          </span>
        </LibraryFolder>
      </ContextMenu>
      {open && (
        <LibraryItemContents
          selectedLanguage={selectedLanguage}
          folderId={id}
          buildIdObject={buildIdObject!}
          skillId={skillId}
          parentDisabled={disabled}
          dialogueType={DIALOGUE_TYPES.REGULAR}
          setShowLoader={setShowLoader}
          indentationLevel={indentationLevel + 1}
        />
      )}
    </>
  );
}

export default FolderItem;
