import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { CreateFolderDocument, type CreateFolderMutationVariables } from 'frontend/api/generated';
import FolderForm from 'frontend/features/Library/modals/FolderForm/FolderForm';
import { useToast } from 'frontend/hooks';
import type { BuildIdObject } from 'frontend/propTypes/BuildIdObjectType';

export type CreateFolderArgs = {
  parentTopicId: string;
  buildIdObject: BuildIdObject;
};

function CreateFolder({
  hide,
  args: { parentTopicId: parentFolderId, buildIdObject },
}: {
  hide: () => void;
  args: CreateFolderArgs;
}) {
  const [create] = useMutation(CreateFolderDocument);
  const toast = useToast();

  const initialValues = useMemo(() => ({ isActive: true }), []);

  const onSubmit = useCallback(
    async (folderInput: CreateFolderMutationVariables['folderInput']) => {
      const result = await create({
        variables: {
          ...buildIdObject,
          folderInput,
          parentFolderId,
        },
      });
      const folder = result!.data!.createFolder!;

      toast.success(`Folder '${folder.name}' was created!'`);
    },
    [buildIdObject, create, parentFolderId, toast],
  );

  return <FolderForm onSubmit={onSubmit} initialValues={initialValues} actionName="New" hide={hide} />;
}

export default CreateFolder;
